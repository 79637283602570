<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="iq-card iq-card-block iq-card-stretch iq-card-height blog blog-detail">
        <div class="iq-card-body">
          <div class="image-block">
            <img v-if="quizDetail && quizDetail.app_image" :src="quizDetail.app_image" class="img-fluid rounded w-100" alt="blog-img"/>
          </div>
          <div class="blog-description mt-2" >
            <h5 class="mb-3 pb-3 border-bottom">
              {{quizDetail && quizDetail.app_name ? quizDetail && quizDetail.app_name : ""}}
            </h5>
            <div class="row blog-meta d-flex align-items-center mb-3">
              <div v-if="(showActionBtns && gpathSession.session_state != GPathData.GPATH_STATE_REPORT_GENERATED && !gpathSession.report) || userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="col-12 sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                <b-btn class="mr-3" variant="primary" size="sm" @click="generateReport">
                  Generate Report
                </b-btn>
                <!-- <b-btn  class="mr-3" variant="primary" size="sm" @click="playAgain">
                  Re-take Assessment
                </b-btn> -->
              </div>
              <div v-if="showErrMsg" class="mt-2 ml-2 primary-color">
                {{ showErrMsg }}
                  <b-btn class="ml-3" variant="primary" size="sm" @click="UpdateProfile">
                    Update Profile
                  </b-btn>
              </div>
              <b-btn class="mr-3" variant="primary" size="sm" @click="openReport(gpathSession.report)" v-if="gpathSession && gpathSession.report">
                  Download Report
              </b-btn>
              <div class="col-12 sm-12 col-md-6 col-lg-6 col-xl-6">
                <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="float-right mr-3 mb-1" variant="primary" size="sm" @click="gPathDashboard()">
                  GPaths DashBoard
                </b-btn>
                <b-btn v-if="userData.user_role=='USERROLE11111' || userData.user_role=='USERROLE11118'" class="float-right mr-3" variant="primary" size="sm" @click="gPathEdit()">
                  Edit
                </b-btn>
              </div>
            </div>
            <p v-if="quizDetail && quizDetail.app_description" class="vhtmlTag vueHtmlParent mt-3" v-html="quizDetail.app_description">
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Upcomming events -->
    <div class="col-lg-4 gide_right_mobile_data">
      <EventHomeList v-if="!sideBarItemsList.Activities.exludeUserRole.includes(userData.user_role)"/>
      <CareerHomeList v-if="!isMobileDevice"/>
    </div>

    <b-toast
      v-model="showToast"
      name="Toast"
      :variant="toastVariant"
      :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>

    <AlertBox :key="showUpgradeAlertBox" v-if="showUpgradeAlertBox" :propAlertTitle="alertTitle" :propAlertDesc="alertDesc" :propOkBtnText="okText"
    :prophidebutton="true"
    :propCancelBtnText="cancelText" @emitCloseAlertModel="showUpgradeAlertBox = false" @emitConfirmAlertModel="goToEventPlanList()"/>
  </div>
</template>

<script>
import { socialvue } from "../../../config/pluginInit"
import AlertBox from "../../../components/AlertBox.vue"
import CareerHomeList from "../ListPages/Career/CareerHomeList.vue"
import EventHomeList from "../Event/EventHomeList.vue"
import GPathData from "../../../FackApi/json/GPath.json"
import { GPathSessions } from "../../../FackApi/api/GPathSession"
import { Apps } from "../../../FackApi/api/apps.js"
import SideBarMenu from "../../../Utils/sidebarMenu.js"
import cacheLs from "../../../store/cacheLs.js"
import { User } from "../../../FackApi/api/user.js"

export default {
  name: "GPathReportGenerate",
  components: {
    AlertBox,
    EventHomeList,
    CareerHomeList
  },
  data () {
    return {
      apiName: "gpath_report_generate",
      cvTitle: "GPaths Generate Report",
      isMobileDevice: window.__IS__MOBILE_DEVICE__,
      toastVariant: "",
      showToast: false,
      toastMsg: "",
      toastTitle: "GPaths",
      showErrMsg: null,
      showUpgradeAlertBox: false,
      alertDesc: "",
      alertTitle: "",
      okText: "Upgrade",
      cancelText: "Not now",
      source: "ONLINE",
      GPathData: GPathData,
      session_state: null,
      quizDetail: null,
      showActionBtns: true,
      app_id: GPathData.GPATH_GENERATE_REPORT_ID,
      sideBarItemsList: SideBarMenu.getSideBarMenuObjList(),
      useLambda: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    },
    gpathSession () {
      return this.$store.getters["GpathSession/gpathSession"]
    }
  },
  mounted () {
    socialvue.index()
    this.appView()
    this.viewGpathSession()
    if (this.gpathSession.session_state == this.GPathData.GPATH_STATE_REPORT_GENERATED) {
      this.showActionBtns = false
    }
    else {
      this.showActionBtns = true
    }
  },
  watch: {
    "$store.state.GpathSession.gpathSession.refreshValue": function (reportUrl, oldData) {
      this.openReport(this.gpathSession.report)
    }
  },
  methods: {
    /**
     * gPathEdit
     */
    gPathEdit () {
      window.open(`quiz_edit/${this.app_id}`, "_blank")
    },
    /**
     * openReport
     */
    openReport (url) {
      window.open(url, "_blank")
    },
    /**
     * UpdateProfile
     */
    UpdateProfile () {
      window.open(`/user_edit/${this.userData.user_id}`, "_blank")
    },
    /**
     * Take Assessment Again
     * Currently not in use
     */
    async playAgain () {
      // Clearing user responses from localstorage
      cacheLs.remove_user_quiz_response(GPathData.GPATH_CAREER)
      cacheLs.remove_user_quiz_response(GPathData.GPATH_APTITUDE)
      cacheLs.remove_user_quiz_response(GPathData.GPATH_SKILL)

      // Setting the gpath session back to payment made
      this.setGpathSessions(GPathData.GPATH_STATE_PAID, GPathData.GPATH_STATE_PAID_MSG)

      let selectedTab = encodeURI(GPathData.GPATH_WORKFLOW_TAB_CAREER_ASSESSMENT)
      this.$router.push(`/gpath_home/#${selectedTab}`)
    },
    /**
     * viewGpathSession
     */
    async viewGpathSession () {
      try {
        // See if the session for the user is created or not
        let payload = {
          user_id: this.userData.user_id
        }
        let gpathSessionViewResp = await GPathSessions.gpath_sessionView(this, null, payload)
        if (gpathSessionViewResp && !gpathSessionViewResp.resp_status) {
          // clearing user's session in local storage
          cacheLs.delete_gpath_session()
          this.$store.dispatch("GpathSession/setGpathSession", {})
          return
        }

        // storing the sessions details in local storage
        this.$store.dispatch("GpathSession/setGpathSession", gpathSessionViewResp.resp_data.data)
      }
      catch (error) {
        console.error("Exception in viewGpathSession() ", error.message)
      }
    },
    /**
     * Generate the report
     */
    async generateReport () {
      // Validate User Profile
      const userProfile = await User.userProfileView(this, this.userData.user_id)
      if (!userProfile.resp_status ||
        (userProfile.resp_status && !userProfile.resp_data.data.user_profile)
      ) {
        this.toastMsg = "Kindly update your personal & education profiles before generating the report."
        this.toastVariant = "danger"
        this.showToast = true
        this.showErrMsg = this.toastMsg
        return
      }

      this.showActionBtns = false

      const postData = {
        user_id: this.userData.user_id,
        user_email: this.userData.user_email,
        gps_id: this.gpathSession.gps_id,
        showHTML: "0",
        filename: "gpaths",
        email_send_flag: "1"
      }

      if (this.useLambda) {
        // Generate report with lambda function
        GPathSessions.gpath_session_report_generate_lambda(this, postData)
        this.toastMsg = "Your report is being generated. It can take upto 5 mins to generate your report. Please note that a copy of the report will be sent to both your registered email address and the parent's email ID shared by you for your reference"
        this.toastVariant = "primary"
        this.showToast = true
      }
      else {
        // Generate report with api service
        let reportResp = await GPathSessions.gpath_session_report_generate(this, postData)
        if (!reportResp.resp_status) {
          this.toastMsg = "Oops! Looks like you have not completed the assessment. Please re-take the assessment. In case the problem persists please reach out to us on support@gide.ai"
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
    },
    /**
     * setGpathSessions
     */
    async setGpathSessions (sessionState, sessionDescription) {
      let payload = {
        user_id: this.userData.user_id,
        gps_id: this.gpathSession.gps_id,
        session_state: sessionState,
        session_state_desc: sessionDescription
      }

      let setGpathSessionResp = await GPathSessions.gpath_sessionEdit(this, payload)
      if (setGpathSessionResp && setGpathSessionResp.resp_status) {
        // Updating the store with the new state
        this.$store.dispatch("GpathSession/setGpathSession", { ...this.gpathSession, session_state: sessionState, session_state_desc: sessionDescription })
      }
    },
    /**
     * goto dashboard
     */
    async gPathDashboard () {
      window.open("/gpath_session_list", "_blank")
    },
    /**
     * Get Info
     */
    async appView () {
      try {
        this.quizId = this.app_id
        const quizResp = await Apps.appView(this, this.quizId)
        this.quizDetail = quizResp.resp_data.data
      }
      catch (error) {
        console.error("Exception in appview()", error.message)
      }
    }
  }
}
</script>
